<template>
  <div>
    <div class="pb-1">
      <input-qr-scan-model
        v-model="filters.keywords"
        :label="$t('labels.mobile_receipt_search')"
        :placeholder="$t('labels.mobile_receipt_search')"
        dense
        outlined
        clearable
        hide-details
        single-line
        @keyupEnter="getList"
      ></input-qr-scan-model>
    </div>

    <v-list
      dense
      style="height: calc(100vh - 170px)"
      class="overflow-x-hidden overflow-y-auto"
    >
      <div v-for="(item, index) in items" :key="`${item.id}_${index}`">
        <v-list-item>
          <v-list-item-content class="pb-0">
            <v-list-item-title class="white-space-normal">{{
              item.goods_name
            }}</v-list-item-title>
            <v-list-item-subtitle
              class="font-weight-regular white-space-normal"
            >
              <span class="">{{ item.goods_description }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular">
              <span class="black--text font-weight-medium">{{
                item.goods_size
              }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular">
              {{ $t("labels.session") }}:
              <span class="font-weight-medium warning--text">{{
                item.tracking_id
              }}</span>
              <span> - </span>
              {{ formatDateTime(item.create_time) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular">
              {{ $t("labels.barcode") }}:
              <span class="font-weight-medium error--text">
                {{ item.customer_goods_barcode }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular">
              {{ $t("labels.request_quantity") }}:

              <span class="font-weight-medium success--text">
                {{ item.request_quantity }}
              </span>
              <span> - </span>

              {{ $t("labels.receipt_quantity") }}:
              <span class="font-weight-medium primary--text">
                {{ item.receipted_quantity }}
              </span>
              <span> - </span>

              {{ $t("labels.sku") }}:
              <span class="font-weight-medium error--text">
                {{ item.sku }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular">
              {{ $t("labels.note") }}: {{ item.note }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-avatar rounded="0">
            <ImageViewer
              :url="item.url_images || require('@/assets/common/no-image.jpg')"
            />
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item>
          <div class="d-flex align-center justify-space-between w-100">
            <div>
              <v-btn
                color="primary"
                x-small
                class="mr-1"
                v-if="!item.status"
                @click="showCheckDialog(item)"
                :disabled="isDisabledBtnCheck(item)"
                >{{ $t("labels.check") }}</v-btn
              >
              <v-btn
                color="warning"
                x-small
                v-if="!item.status"
                @click="updateStatus(item, 'confirm')"
                :disabled="isDisabledBtnStatus(item)"
                >{{ $t("labels.done_1") }}</v-btn
              >
              <v-btn
                color="secondary"
                x-small
                v-if="item.status"
                @click="updateStatus(item, 'reopen')"
                :disabled="isDisabledBtnStatus(item)"
                >{{ $t("labels.reopen") }}</v-btn
              >
            </div>
          </div>
        </v-list-item>
        <v-list-item v-if="checkDialog" class="mb-2">
          <CheckNormalGoods
            v-if="
              checkDialog &&
              updatingItem &&
              updatingItem.id === item.id &&
              !updatingItem.goods_special
            "
            :item="updatingItem"
            @cancel="hideCheckDialog"
            @refreshData="getList"
          />
          <CheckSpecialGoods
            v-if="
              checkDialog &&
              updatingItem &&
              updatingItem.id === item.id &&
              updatingItem.goods_special
            "
            :item="updatingItem"
            @cancel="hideCheckDialog"
            @refreshData="getList"
          />
        </v-list-item>
        <v-divider v-if="index < items.length - 1"></v-divider>
      </div>
    </v-list>

    <v-divider></v-divider>
    <div class="d-flex align-center justify-space-between pt-1">
      <div>
        <div class="table-footer-count mr-2">
          {{ $t("labels.sku") }}:
          <b
            >{{ formatNumber(sum.sumChecked) }}/{{
              formatNumber(sum.sumGoods)
            }}</b
          >
        </div>
        <div class="d-flex table-footer-count mt-1">
          <div class="mr-2">
            {{ $t("labels.request_quantity") }}:
            <b>{{ formatNumber(sum.sumRequestQuantity) }}</b>
          </div>
          <div class="mr-2">
            {{ $t("labels.receipt_quantity") }}:
            <b>{{ formatNumber(sum.sumReceiptedQuantity) }}</b>
          </div>
        </div>
      </div>
      <div>
        <v-pagination
          class="mini-pagination"
          v-model="page"
          :length="totalPage"
          :total-visible="2"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import waitingMixins from "@/components/pos_goods/mixins/waitingMixins";

export default {
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
    CheckNormalGoods: () => import("@/components/pos_goods/CheckNormalGoods"),
    CheckSpecialGoods: () => import("@/components/pos_goods/CheckSpecialGoods"),
  },
  name: "WaitingMobile",
  mixins: [waitingMixins],
};
</script>

<style scoped></style>
